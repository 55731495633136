<template>
  <div id="resume">
    <div class="resume-wrapper">
      <b-container>
        <h1 class="titles">Submissão de resumos</h1>
        <b-form @submit.prevent="onSubmit" class="w-75 mt-5 m-auto">
          <b-row class="mb-3">
            <b-col md="12" sm="12">
              <b-form-group
                label="Informe o tipo de resumo:"
                label-for="form.resume_type"
              >
                <b-form-select
                  class="w-100 p-2 mt-3 border bg-light"
                  v-model="form.resume_type"
                  value-field="text"
                  text-field="text"
                >
                  <b-form-select-option :value="null" disabled
                    >Escolha uma opção:</b-form-select-option
                  >
                  <b-form-select-option value="simples"
                    >Resumo Simples</b-form-select-option
                  >

                  <b-form-select-option value="completo"
                    >Resumo Completo</b-form-select-option
                  ></b-form-select
                >
              </b-form-group>
            </b-col>
          </b-row>
          <p>SUBMISSÃO DE RESUMOS</p>
          <p>Prazo para envio 01/01/1995</p>
          <p>
            A submissão de resumos é gratuita, entretanto é necessário que um
            dos autores esteja devidamente inscrito no evento Leia com atenção
            as orientações e regras para o envio do trabalho científico.
          </p>

          <p class="mt-5 mb-5">ORIENTAÇÕES PARA SUBMISSÃO DE RESUMOS</p>

          <p>Somente serão aceitos os resumos que:</p>

          <p>
            a) sejam submetidos no prazo estabelecido pela organização do evento
          </p>

          <p>
            b) sigam as normas para elaboração dos resumos estabelecidas pela
            comissão organizadora do evento;
          </p>

          <p>
            c) os assuntos deverão ser pertinentes a área de Acupuntura
            Veterinária ou áreas correlatas.
          </p>

          <p>
            d) sejam originais, ou seja, não tenham sido apresentados em nenhum
            outro evento ou publicados em revista científica no momento do envio
            do trabalho
          </p>

          <p>e) sejam escritos nos idiomas português, espanhol ou inglês.</p>

          <p>
            f) o número máximo de trabalhos apresentados por um mesmo autor será
            de três, independente da ordem de colocação do autor no trabalho.
          </p>

          <p class="mt-5 mb-5">NORMAS PARA ELABORAÇÃO DOS RESUMOS</p>

          <p>
            O texto deve ser redigido em português, constituído de título, nomes
            dos autores (exemplo João P. C. Silva1) e sublinhado o apresentador
            que deverá estar inscrito no XI Congresso Brasileiro de Acupuntura
            Veterinária na ocasião de submissão do trabalho, endereço completo
            de cada um dos autores identificado com número sobrescrito no nome,
            e-mail do autor principal, introdução, metodologia, resultado(s) e
            conclusão (ões). Tais palavras, entretanto, não devem constar no
            texto, mas essa sequência é recomendada. Para a digitação deve-se
            usar o editor de textos Word for Windows, fonte Times New Roman,
            tamanho 11, em espaço 1,5, folha A4, com 2,5 cm de margens esquerda
            e direita e 3,0 cm de margem superior e inferior. O texto do resumo,
            incluindo título, autores, endereço e e-mail para contato, corpo do
            texto, referências bibliográficas (quando necessário) e rodapé não
            deverá ultrapassar 350 palavras. Caso seja indispensável a citação
            bibliográfica no resumo, pode-se citar as referências, seguindo
            normas da ABNT, ao final do texto.
          </p>

          <p>
            Cabeçalho: O título do trabalho deverá ser digitado em letras
            maiúsculas (caixa alta) e em negrito; nomes científicos devem ser
            digitados em itálico/negrito no título e apenas em itálico no texto
            do resumo (somente a primeira letra, do primeiro nome científico, em
            maiúscula). Após o título, digitar os nomes dos autores por extenso
            com notas de rodapé indicando sua instituição ou estabelecimento de
            origem, endereço e e-mail.
          </p>
          <p>
            Comissão de ética: os trabalhos que envolverem animais e seres
            humanos deverão ser encaminhados apenas após a aprovação prévia da
            Comissão de Ética da instituição de origem com o número do protocolo
            de aprovação inserido no final do resumo. Em casos em que não haja
            Comissão de Ética na instituição ou em casos de Médicos Veterinários
            autônomos, a Comissão Científica poderá negar o trabalho caso não
            esteja de acordo com os preceitos de bem-estar animal.
          </p>

          <p>Observações:</p>
          <p>
            Não serão permitidas ilustrações, quadros e/ou tabelas no resumo.
          </p>

          <p>
            A revisão ortográfica e gramatical do trabalho deverá ser feita
            antes da submissão e será de responsabilidade dos autores.
          </p>

          <p class="mt-5 mb-5">AVALIAÇÃO</p>

          <p>
            Resumos que não estiverem de acordo com as normas serão
            automaticamente reprovados e o mérito científico não será avaliado.
            Resumos com erros ortográficos e gramaticais que necessitarem de
            grandes correções e alterações poderão ser reprovados a critério da
            Comissão de Avaliação de Trabalhos.
          </p>

          <p>
            O parecer da avaliação estará disponível da área de inscrição do
            autor principal até 20/05/2021. As decisões da comissão científica
            serão irrevogáveis, não cabendo recurso.
          </p>

          <p>ANAIS DO CONGRESSO</p>
          <p>
            Os trabalhos aprovados pela comissão científica serão publicados sob
            a forma digital na Revista Brasileira de Acupuntura Veterinária.
          </p>
          <!-- <div v-if="form.resume_type == 'simples'"> -->
          <!-- RESUMO SIMPLES -->
          <!-- <v-card min-height="300" raised class="m-auto">
              <v-card-title class="m-auto">
                <h3 class="m-auto">Submissão de resumo simples</h3>
              </v-card-title>
              <br />
              <v-card-text>
                <v-file-input
                  accept=".pdf"
                  label="Adicione aqui seu resumo"
                  outlined
                  multiple
                  v-model="form.chosenFile"
                  @change="fileUpload"
                >
                </v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn right @click="addFile(form.chosenFile)"
                  >Adicionar Resumo</v-btn
                >
                <v-btn left @click="submitFiles()">Enviar Arquivo</v-btn>
              </v-card-actions>
            </v-card> -->

          <!-- FIM RESUMO SIMPLES -->
          <!-- </div> -->
          <!-- RESUMO COMPLETO -->
          <div
            class="resume-complete w-100"
            v-if="
              form.resume_type == 'simples' || form.resume_type == 'completo'
            "
          >
            <v-card min-height="300" raised class="m-auto">
              <v-card-title class="m-auto">
                <h3 v-if="form.resume_type == 'completo'" class="m-auto mt-4">
                  Submissão de resumo Completo
                </h3>
                <h3 v-if="form.resume_type == 'simples'" class="m-auto mt-4">
                  Submissão de resumo Simples
                </h3>
              </v-card-title>
              <br />
              <v-card-text>
                <!-- DOCUMENTO -->
                <v-file-input
                  accept=".pdf"
                  label="Adicione aqui seu resumo"
                  outlined
                  v-model="chosenFile"
                  @change="addFile(chosenFile)"
                >
                </v-file-input>
                <v-checkbox
                  v-model="form.apresentation"
                  label="Desejo que meu resumo concorra a apresentação oral"
                  color="error"
                  value="error"
                  hide-details
                ></v-checkbox>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
               
                <v-btn
                  dark
                  color="primary"
                  left
                  @click="onUploadFile()"
                  :disabled="chosenFiles.length == 0"
                  >Enviar Arquivo</v-btn
                >
              </v-card-actions> -->
            </v-card>
            <!-- FIM RESUMO COMPLETO -->
            <v-card min-height="300" raised class="mt-5">
              <v-card-title>
                <h3 class="m-auto">Autores</h3>
              </v-card-title>
              <v-list subheader two-line flat>
                <v-subheader
                  v-if="form.resume.authors.length < 5"
                  class="text-center"
                  >Autores - {{ form.resume.authors.length }}</v-subheader
                >
                <v-subheader v-else class="text-center"
                  >Autores (máximo permitido) -
                  {{ form.resume.authors.length }}</v-subheader
                >

                <v-list-item-group multiple raised>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="newAuthor"
                          label="Adicionar Co-Autor (Max. 5 autores)"
                          outlined
                          :disabled="form.resume.authors.length == 5"
                          clearable
                          @keyup.enter="addAuthor(newAuthor)"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn x-large @click="addAuthor(newAuthor)" dark :disabled="form.resume.authors.length == 5"
                          >Adicionar autor</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <div
                    v-for="(author, index) in this.form.resume.authors"
                    :key="index"
                  >
                    <v-list-item>
                      <template>
                        <v-list-item-action>
                          <!-- <v-checkbox :input-value="task.done" color="primary"></v-checkbox> -->
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{ author }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn @click="removeAuthor(author)" icon>
                            <v-icon color="red ">mdi-trash-can</v-icon>
                          </v-btn>
                        </v-list-item-action>

                       
                      </template>
                    </v-list-item>
                   
                    <!-- <Task :task="task"></Task> -->
                  </div>
                     <v-card-actions>
                <!-- <v-btn right @click="addFile(form.chosenFile)"
                  >Adicionar Resumo</v-btn
                > -->
                <v-btn
                  dark
                  color="primary"
                  left
                  @click="onUploadFile()"
                  :disabled="chosenFiles.length == 0"
                  >Enviar</v-btn
                >
              </v-card-actions>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
// import Authors from "../components/Authors.vue";
import axios from "axios";
export default {
  data() {
    return {
      chosenFiles: [],
      chosenFile: null,
      newAuthor: "",
      form: {
        resume: {
          authors: [],
          data: null,
          apresentation: false,
        },
        resume_type: null,
      },
    };
  },
  methods: {
    addFile(file) {
      this.chosenFiles.push(file);
      // console.log(this.chosenFiles);
      console.log(file);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.chosenFile[0]); // appending file

      axios
        .post("http://localhost:3000/posts", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch(() => {
          // console.log(err.res.data);
        });
    },

    // fileUpload() {},
    // async sendFile() {
    //   let dataForm = new FormData();
    //   for (let file of this.chosenFiles) {
    //     dataForm.append(`file`, file);
    //   }
    //   let res = await fetch(`http://localhost:3000/posts`, {
    //     method: 'POST',
    //     body: dataForm,
    //     title:this.file
    //   });
    //   let data = await res.json();
    //   console.log(data);
    // },

    addAuthor(author) {
      if(author !== ''){

        this.form.resume.authors.push(author);
      this.newAuthor = "";
      }
    },
    removeAuthor(author) {
      console.log(author);
      let newAuthors = this.form.resume.authors.filter((auth) => {
        return auth !== author;
      });
      this.form.resume.authors = newAuthors;
    },
    onSubmit() {
      console.log(this.form.resume);
    },
  },
};
</script>

<style scoped>
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.resume-wrapper {
  min-width: 100%;
  min-height: 100vh;
  padding-bottom: 10px;
  background-color: rgb(241, 247, 247);
  text-align: center;
}
form {
  text-align: left;
}
form p {
  margin: 35px 0;
}
</style>